import { Loading } from "components/core/modal/loading"
import { WidgetConfig, WidgetModalProps } from "components/dashboard/grid/config"
import { UrlConverterPreview } from "components/dashboard/widgets/urlConverter/urlConverterPreview"
import dynamic from "next/dynamic"
import React from "react"

const UrlConverterModal = dynamic(() => import("./urlConverterModal"), { loading: () => <Loading /> })

export const urlConverterConfig: WidgetConfig = {
  slug: "url-converter",
  renderPreview: (widget) => <UrlConverterPreview widget={widget} />,
  renderModal: (props?: WidgetModalProps<string>) => <UrlConverterModal {...props} />,
  icon: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.877 14.123a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.9a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.655l-1.1 1.1"
        stroke="currentColor"
        strokeWidth={1.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
}
