import { Loading } from "components/core/modal/loading"
import { WidgetConfig, WidgetModalProps } from "components/dashboard/grid/config"
import { PackageSizePreview } from "components/dashboard/widgets/packageSize/packageSizePreview"
import dynamic from "next/dynamic"
import React from "react"

const PackageSizeModal = dynamic(() => import("./packageSizeModal"), { loading: () => <Loading /> })

export const packageSizeConfig: WidgetConfig = {
  slug: "package-size",
  renderPreview: (widget) => <PackageSizePreview widget={widget} />,
  renderModal: (props?: WidgetModalProps<string>) => <PackageSizeModal {...props} />,
  icon: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.087 9.54494L16.489 4.17476L16.4846 4.17256C16.0127 3.93761 15.452 3.94286 14.9845 4.18662L10.4391 6.59914C10.4022 6.6148 10.3667 6.63378 10.3327 6.65563L4.88575 9.54667C4.33937 9.83277 4 10.3933 4 11.0103V20.9897C4 21.6066 4.33937 22.1671 4.88575 22.4532L14.98 27.811L14.9846 27.8134C15.2205 27.9362 15.4826 28.0002 15.7485 28C16.0039 28.0002 16.2559 27.9411 16.4846 27.8274L27.087 22.455C27.6502 22.1733 28 21.6074 28 20.9775V11.0225C28 10.3925 27.6502 9.82658 27.087 9.54494ZM15.6361 5.43231C15.6702 5.41488 15.7079 5.40563 15.7462 5.40528C15.7844 5.40494 15.8223 5.41352 15.8567 5.43034L25.7768 10.457L21.916 12.3793L12.2176 7.24676L15.6361 5.43231V5.43231ZM15.0355 26.2489L5.54266 21.2103L5.53811 21.2079C5.49815 21.1872 5.46467 21.1559 5.44132 21.1174C5.41798 21.0789 5.40567 21.0347 5.40573 20.9897V11.6446L15.0355 16.6664V26.2489ZM15.7458 15.4513L6.169 10.4572L10.717 8.0433L20.3674 13.1503L15.7458 15.4513V15.4513ZM26.5942 20.9774C26.5944 21.0232 26.5817 21.0682 26.5575 21.1072C26.5334 21.1462 26.4988 21.1776 26.4577 21.1979L16.4413 26.2734V16.6754L21.0531 14.3793V16.7867C21.0531 17.1749 21.3678 17.4896 21.756 17.4896C22.1442 17.4896 22.4589 17.1749 22.4589 16.7867V13.6793L26.5942 11.6204V20.9774V20.9774Z"
        fill="currentColor"
      />
    </svg>
  ),
}
