import { Loading } from "components/core/modal/loading"
import { WidgetConfig, WidgetModalProps } from "components/dashboard/grid/config"
import dynamic from "next/dynamic"
import React from "react"

const CssTriangleModal = dynamic(() => import("./cssTriangleModal"), { loading: () => <Loading /> })

export const cssTriangleConfig: WidgetConfig = {
  slug: "css-triangle",
  renderModal: (props?: WidgetModalProps) => <CssTriangleModal {...props} />,
  icon: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.73205 24L16.3923 9L25.0526 24H7.73205Z" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  ),
}
