import {
  BundlephobiaPackageHistory,
  BundlephobiaPackage,
  PackageSuggestion,
  BundlephobiaPackageError,
} from "components/dashboard/widgets/packageSize/types"
import { useQuery } from "react-query"

export const useBundlephobia = (packageName?: string) =>
  useQuery<{ package: BundlephobiaPackage | BundlephobiaPackageError; history: BundlephobiaPackageHistory }>(
    ["bundlephobia", packageName],
    async () => {
      const res = await fetch(`/api/bundlephobia?packageName=${packageName}`)
      const json = await res.json()

      return json
    },
    {
      enabled: !!packageName,
    }
  )

export const useNpm = (searchQuery?: string) =>
  useQuery<PackageSuggestion[]>(
    ["npm", searchQuery],
    async () => {
      const res = await fetch(`/api/npm?packageName=${searchQuery}`)
      const json = await res.json()

      return json
    },
    {
      enabled: !!searchQuery && searchQuery.length > 1,
    }
  )
