import React, { FC, useState } from "react"
import { Modal } from "components/core/modal/modal"
import { Button } from "components/core/button"
import { TextareaLabel } from "components/core/textareaLabel"
import { jsonConverterConfig } from "components/dashboard/widgets/jsonConverter/jsonConverterConfig"
import { Widget } from "domainModels/widget"

interface Props {
  widget: Widget
}

export const FormatJsonPreview: FC<Props> = ({ widget }) => {
  const [showModal, setShowModal] = useState(false)
  const [input, setInput] = useState("")

  return (
    <div className="flex flex-col h-full">
      <TextareaLabel
        id="jsonInput"
        value={input}
        placeholder="Paste your code here"
        onChange={(ev) => setInput(ev.target.value)}
        onPaste={(_ev) => {
          setTimeout(() => {
            setShowModal(true)
          }, 200)
        }}
      />

      <Button variant="secondary" onClick={() => setShowModal(true)} className="z-10 mt-3 self-end">
        Show formatted
      </Button>

      <Modal show={showModal} className="max-w-[90vw]" onClose={() => setShowModal(false)}>
        {jsonConverterConfig.renderModal({ input, widget })}
      </Modal>
    </div>
  )
}
