import React, { FC, Fragment, ReactNode } from "react"
import { EllipsisVerticalIcon, LockClosedIcon, LockOpenIcon, TrashIcon } from "@heroicons/react/24/outline"
import { Menu, Transition } from "@headlessui/react"
import { Layout as LayoutDomainModel } from "domainModels/layout"
import { useUpdateLayout, useUpdateLayouts } from "reactQuery/layout"
import { useCurrentDashboard } from "reactQuery/dashboard"
import { WidgetMenuContent, WidgetMenuContentItem } from "components/dashboard/widgets/shared/widgetMenuContent"
import { useUser } from "lib/supabase/auth"
import toast from "react-hot-toast"
import { TOAST_WIDGET_UNLOCK, TOAST_WIDGET_LOCK } from "components/dashboard/toastConfig"

interface Props {
  isLocked?: boolean
  layout: LayoutDomainModel | null
  onRemove?: () => void
}

export const WidgetActionsMenu: FC<Props> = ({ layout, isLocked, onRemove }) => {
  const { user } = useUser()
  const { data: dashboard } = useCurrentDashboard()
  const { mutateAsync: updateLayout } = useUpdateLayout()
  const { mutateAsync: updateLayouts } = useUpdateLayouts()
  const menuItems: ReactNode[] = []

  const toggleLock = async () => {
    if (layout && dashboard?.id) {
      const currentStatic = layout?.xxl.static
      const toastMsg = currentStatic ? TOAST_WIDGET_UNLOCK : TOAST_WIDGET_LOCK
      const newLayout = {
        ...layout,
        xs: { ...layout.xs, static: !currentStatic },
        md: { ...layout.md, static: !currentStatic },
        lg: { ...layout.lg, static: !currentStatic },
        xl: { ...layout.xl, static: !currentStatic },
        xxl: { ...layout.xxl, static: !currentStatic },
      }

      if (user) {
        await toast.promise(updateLayout({ layout: newLayout, dashboardId: dashboard.id }), toastMsg)
      } else {
        await toast.promise(updateLayouts({ layouts: [newLayout], dashboardId: dashboard.id }), toastMsg)
      }
    }
  }

  menuItems.push(
    <WidgetMenuContentItem onClick={toggleLock}>
      {isLocked ? (
        <>
          <LockOpenIcon className="h-4 w-4 mr-2 text-gray-400 dark:text-gray-500" />
          Unlock
        </>
      ) : (
        <>
          <LockClosedIcon className="h-4 w-4 mr-2 text-gray-400 dark:text-gray-500" />
          Lock
        </>
      )}
    </WidgetMenuContentItem>
  )

  if (onRemove) {
    menuItems.push(
      <WidgetMenuContentItem onClick={onRemove}>
        <TrashIcon className="h-4 w-4 mr-2 text-gray-400 dark:text-gray-500" />
        Remove
      </WidgetMenuContentItem>
    )
  }

  return (
    <>
      <Transition
        show={!!isLocked}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute top-2 right-8">
          <LockClosedIcon className="w-4 h-4 opacity-40" />
        </div>
      </Transition>

      <Menu as={Fragment}>
        {({ open }) => (
          <WidgetMenuContent
            open={open}
            menuItems={menuItems}
            trigger={
              <Menu.Button
                title="Open menu"
                className="flex items-center transition-all ml-auto px-2 py-1.5 opacity-70 hover:opacity-100 focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:opacity-100 rounded-md"
              >
                <EllipsisVerticalIcon className="h-5 w-5" />
              </Menu.Button>
            }
          />
        )}
      </Menu>
    </>
  )
}
