import React, { FC, useState } from "react"
import { Modal } from "components/core/modal/modal"
import { Button } from "components/core/button"
import { TextareaLabel } from "components/core/textareaLabel"
import { Widget } from "domainModels/widget"
import { jsConverterConfig } from "components/dashboard/widgets/jsConverter/jsConverterConfig"

interface Props {
  widget: Widget
}

export const FormatJsPreview: FC<Props> = ({ widget }) => {
  const [showModal, setShowModal] = useState(false)
  const [input, setInput] = useState("")

  return (
    <>
      <div className="flex flex-col h-full">
        <TextareaLabel
          id="jsInput"
          value={input}
          placeholder="Paste your code here"
          onChange={(ev) => setInput(ev.target.value)}
          onPaste={(_ev) => {
            setTimeout(() => {
              setShowModal(true)
            }, 200)
          }}
        />

        <Button variant="secondary" onClick={() => setShowModal(true)} className="z-10 mt-3 self-end">
          Show formatted
        </Button>
      </div>
      <Modal show={showModal} className="max-w-[90vw]" onClose={() => setShowModal(false)}>
        {jsConverterConfig.renderModal({ input, widget })}
      </Modal>
    </>
  )
}
