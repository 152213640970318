import React, { FC, useState } from "react"
import { PackageSizeSearch } from "components/dashboard/widgets/packageSize/packageSizeSearch"
import { Modal } from "components/core/modal/modal"
import { Widget } from "domainModels/widget"
import { packageSizeConfig } from "components/dashboard/widgets/packageSize/packageSizeConfig"

interface Props {
  widget: Widget
}

export const PackageSizePreview: FC<Props> = ({ widget }) => {
  const [input, setInput] = useState<string>()
  const [searchQuery, setSearchQuery] = useState("")

  return (
    <>
      <div className="h-full w-full flex items-center justify-center max-w-[300px] mx-auto">
        <PackageSizeSearch
          id="packageSizeSearchPreview"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onChange={(suggestion) => setInput(suggestion.package.name)}
        />
      </div>
      <Modal show={!!input} className="md:max-w-7xl" onClose={() => setInput(undefined)}>
        {packageSizeConfig.renderModal({ input, widget })}
      </Modal>
    </>
  )
}
