import { useMutation, useQueryClient } from "react-query"
import { Layout } from "domainModels/layout"
import { insertLayout, updateLayout } from "repository/layout"
import { Widget } from "domainModels/widget"
import { getWidgetsFromLocalStorage, getWidgetsKey, WIDGETS_LOCAL_STORAGE_KEY } from "reactQuery/widget"
import { Group } from "domainModels/group"
import { getGroupsFromLocalStorage, getGroupsKey, GROUPS_LOCAL_STORAGE_KEY } from "reactQuery/group"
import { useUser } from "lib/supabase/auth"
import { nanoid } from "nanoid"

// TODO combine with useUpdateLayouts
export const useUpdateLayout = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, { layout: Layout; dashboardId: number }>(async ({ layout, dashboardId }) => {
    queryClient.setQueryData<Widget[]>(getWidgetsKey(dashboardId), (widgets = []) =>
      widgets.map((widget) =>
        widget.layout?.id === layout.id
          ? {
              ...widget,
              layout,
            }
          : widget
      )
    )

    queryClient.setQueryData<Group[]>(getGroupsKey(dashboardId), (groups = []) =>
      groups.map((group) =>
        group.layout?.id === layout.id
          ? {
              ...group,
              layout,
            }
          : group
      )
    )

    await updateLayout(layout)
  })
}

export const useUpdateLayouts = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  return useMutation<void, Error, { layouts: Layout[]; dashboardId: number }>(async ({ layouts, dashboardId }) => {
    if (user) {
      return
    }

    const widgets = getWidgetsFromLocalStorage()
    const groups = getGroupsFromLocalStorage()

    const layoutIds = layouts.map((l) => l.id)
    const newWidgets = widgets?.map((w) => {
      if (w.layout?.id && layoutIds.includes(w.layout?.id)) {
        return { ...w, layout: layouts.find((l) => l.id === w.layout?.id) ?? null }
      }
      return w
    })
    const newGroups: Group[] = groups?.map((g) => {
      if (layoutIds.includes(g.layout?.id)) {
        return { ...g, layout: layouts.find((l) => l.id === g.layout?.id)! }
      }
      return g
    })

    if (newWidgets) {
      queryClient.setQueryData<Widget[]>(getWidgetsKey(dashboardId), newWidgets)
      localStorage.setItem(WIDGETS_LOCAL_STORAGE_KEY, JSON.stringify(newWidgets))
    }

    if (newGroups) {
      queryClient.setQueryData<Group[]>(getGroupsKey(dashboardId), newGroups)
      localStorage.setItem(GROUPS_LOCAL_STORAGE_KEY, JSON.stringify(newGroups))
    }
  })
}

export const useInsertLayout = () => {
  const { user } = useUser()

  return useMutation<Layout, Error, Layout>(async (layout) => {
    if (user) {
      return await insertLayout(layout)
    } else {
      // TODO
      return { ...layout, id: nanoid() }
    }
  })
}
