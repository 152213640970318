import { FC, InputHTMLAttributes, ReactNode } from "react"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputBefore?: ReactNode
}

export const InlineEdit: FC<Props> = ({ inputBefore, ...rest }) => (
  <label className="inline-edit" data-value={rest.value?.toString().length === 0 ? rest.placeholder : rest.value}>
    {inputBefore}
    <input
      size={2}
      type="text"
      spellCheck="false"
      onInput={(ev) => {
        ;(ev.target as any).parentNode.dataset.value = (ev.target as any).value
      }}
      {...rest}
    />
  </label>
)
