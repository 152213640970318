import React, { FC, useState } from "react"
import { Button } from "components/core/button"
import { copyToClipboard } from "components/core/copyButton"
import { InputLabel } from "components/core/inputLabel"
import { Widget } from "domainModels/widget"

interface Props {
  widget: Widget
}

export const UrlConverterPreview: FC<Props> = () => {
  const [url, setUrl] = useState("")

  return (
    <>
      <div className="h-full w-full flex items-center justify-center">
        <InputLabel
          id="urlConverter"
          type="text"
          value={url}
          aria-label="Enter url to decode/encode"
          onChange={(ev) => setUrl(ev.target.value)}
          className="relative z-10"
          inputAfter={
            <div className="flex">
              <Button
                variant="secondary"
                className="rounded-none focus-visible:z-10 -ml-px"
                onClick={() => {
                  if (url.length > 0) {
                    setUrl(encodeURIComponent(url))
                    copyToClipboard(encodeURIComponent(url))
                  }
                }}
              >
                Encode
              </Button>
              <Button
                variant="secondary"
                className="-ml-px rounded-l-none focus-visible:z-10"
                onClick={() => {
                  if (url.length > 0) {
                    setUrl(decodeURIComponent(url))
                    copyToClipboard(decodeURIComponent(url))
                  }
                }}
              >
                Decode
              </Button>
            </div>
          }
        />
      </div>
    </>
  )
}
