import { Loading } from "components/core/modal/loading"
import { WidgetConfig, WidgetModalProps } from "components/dashboard/grid/config"
import { Base64ConverterPreview } from "components/dashboard/widgets/base64Converter/base64ConverterPreview"
import dynamic from "next/dynamic"
import React from "react"

const Base64ConverterModal = dynamic(() => import("./base64ConverterModal"), { loading: () => <Loading /> })

export const base64ConverterConfig: WidgetConfig = {
  slug: "base64-converter",
  renderPreview: (widget) => <Base64ConverterPreview widget={widget} />,
  renderModal: (props?: WidgetModalProps<string>) => <Base64ConverterModal {...props} />,
  icon: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.403 9.603a.492.492 0 01.43.185c.11.13.165.369.165.717 0 .212-.035.366-.103.462a.34.34 0 01-.277.143c-1.818.096-3.216.568-4.194 1.415-.977.841-1.562 1.986-1.753 3.435a3.636 3.636 0 011.312-1.076 3.746 3.746 0 011.662-.38c.752 0 1.428.161 2.03.482A3.512 3.512 0 0114.1 16.35c.349.588.523 1.265.523 2.03 0 .739-.174 1.402-.523 1.99a3.594 3.594 0 01-1.487 1.363c-.636.328-1.367.493-2.194.493-.745 0-1.432-.171-2.061-.513a3.932 3.932 0 01-1.507-1.487c-.527-.902-.79-2.061-.79-3.476 0-1.183.25-2.3.749-3.353.505-1.053 1.305-1.928 2.399-2.625 1.1-.704 2.498-1.094 4.194-1.169zm-2.984 6.419a3.05 3.05 0 00-1.343.287 2.231 2.231 0 00-.933.78 2.004 2.004 0 00-.329 1.138c0 .478.106.905.318 1.281.22.376.523.67.913.882.396.212.854.318 1.374.318.52 0 .974-.096 1.364-.287.39-.191.69-.462.902-.81.212-.356.318-.766.318-1.23 0-.472-.106-.886-.318-1.241a2.039 2.039 0 00-.892-.82c-.383-.199-.84-.298-1.374-.298zm11.983-6.419c.595 0 .893.267.893.8v6.511h1.66c.233 0 .397.055.493.164.102.103.154.28.154.533s-.052.434-.154.544c-.096.102-.26.154-.492.154h-1.661v2.296h1.353c.233 0 .397.055.492.165.103.102.154.28.154.533s-.051.434-.154.543c-.095.103-.26.154-.492.154h-4.41c-.232 0-.399-.051-.502-.154-.095-.11-.143-.29-.143-.543 0-.253.048-.43.143-.534.103-.109.27-.163.503-.163h1.517v-2.297h-4.9c-.233 0-.4-.052-.503-.154-.096-.11-.144-.29-.144-.544 0-.225.034-.386.103-.482l4.963-6.9c.15-.212.31-.37.482-.472.17-.103.386-.154.645-.154zm-4.152 7.311h3.506l.082-5.086-3.588 5.086z"
        fill="currentColor"
      />
    </svg>
  ),
}
