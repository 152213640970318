import { FileUpload } from "components/core/fileUpload"
import React, { ChangeEvent, FC } from "react"

export interface FileUpload {
  src: string
  size: number
  file: File
}

interface Props {
  onChange: (image: FileUpload) => void
}

export const ImageFileUpload: FC<Props> = ({ onChange }) => {
  const onUpload = (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = function () {
        onChange({
          src: reader.result as string,
          size: file.size,
          file,
        })
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <form encType="multipart/form-data" onSubmit={(ev) => ev.preventDefault()} className="h-full flex">
      <FileUpload id="imageUpload" accept="image/*" onUpload={onUpload} />
    </form>
  )
}
