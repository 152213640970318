import { FileUpload } from "components/core/imageFileUpload"
import { Loading } from "components/core/modal/loading"
import { WidgetConfig, WidgetModalProps } from "components/dashboard/grid/config"
import { ImageOptimizerPreview } from "components/dashboard/widgets/imageOptimizer/imageOptimizerPreview"
import dynamic from "next/dynamic"
import React from "react"

const ImageOptimizerModal = dynamic(() => import("./imageOptimizerModal"), { loading: () => <Loading /> })

export const imageOptimizerConfig: WidgetConfig = {
  slug: "image-converter",
  renderPreview: (widget) => <ImageOptimizerPreview widget={widget} />,
  renderModal: (props?: WidgetModalProps<FileUpload>) => <ImageOptimizerModal {...props} />,
  icon: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.1888 5H7.81123C6.26109 5 5 6.26113 5 7.81127V24.1888C5 25.7389 6.26109 27 7.81123 27H24.1888C25.7389 27 27 25.7389 27 24.1887V7.81127C27 6.26113 25.7389 5 24.1888 5ZM25.7041 24.1888C25.7041 25.0244 25.0243 25.7041 24.1888 25.7041H7.81123C6.97562 25.7041 6.29581 25.0243 6.29581 24.1888V21.8252L10.5607 18.1964C10.6358 18.1323 10.7312 18.0968 10.83 18.0962C10.9287 18.0957 11.0245 18.13 11.1004 18.1932L13.772 20.4116C14.0296 20.6255 14.4077 20.6079 14.6444 20.371L20.9922 14.0136C21.1069 13.8986 21.2406 13.8874 21.3105 13.891C21.3801 13.8946 21.5122 13.9194 21.6146 14.0455L25.7042 19.081V24.1888H25.7041ZM25.7041 17.0254L22.6205 13.2285C22.4689 13.0421 22.2799 12.8895 22.0656 12.7807C21.8514 12.6719 21.6168 12.6093 21.3769 12.5968C20.8927 12.5723 20.4179 12.7546 20.0752 13.0979L14.1451 19.0371L11.9284 17.1963C11.618 16.9378 11.2262 16.7973 10.8222 16.7998C10.4183 16.8022 10.0282 16.9473 9.72093 17.2096L6.29581 20.1238V7.81127C6.29581 6.97566 6.97562 6.29585 7.81123 6.29585H24.1888C25.0244 6.29585 25.7041 6.97566 25.7041 7.81127V17.0254Z"
        fill="currentColor"
      />
      <path
        d="M12 8C10.3458 8 9 9.34584 9 11C9 12.6542 10.3458 14 12 14C13.6542 14 15 12.6542 15 11C15 9.3458 13.6542 8 12 8V8ZM12 12.7558C11.0318 12.7558 10.2442 11.9681 10.2442 11C10.2442 10.0318 11.0318 9.24422 12 9.24422C12.9682 9.24422 13.7558 10.0319 13.7558 11C13.7558 11.9681 12.9682 12.7558 12 12.7558V12.7558Z"
        fill="currentColor"
      />
    </svg>
  ),
}
