import { Title } from "components/core/modal/title"
import { trackCustomWidgetView } from "lib/gtag"
import React, { FC, useEffect, useState } from "react"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline"
import { Widget, WidgetInformation } from "domainModels/widget"
import { MarkdownWidgetContent } from "components/dashboard/widgets/customWidgets/markdownWidgetContent"
import { Button } from "components/core/button"
import { useCurrentDashboard, useIsDashboardOwner } from "reactQuery/dashboard"
import { ExternalUrlIframe } from "components/dashboard/widgets/customWidgets/externalUrlIframe"
import { InlineEdit } from "components/core/inlineEdit"
import { TOAST_CUSTOM_WIDGET_UPDATE } from "components/dashboard/toastConfig"
import toast from "react-hot-toast"
import { useSelectPublicWidgets, useUpdateWidget } from "reactQuery/widget"
import { Link } from "components/core/link"

interface Props {
  widget: Widget
  isPreview?: boolean
}

export const CustomWidgetModal: FC<Props> = ({ widget, isPreview }) => {
  const [showIframe, setShowIframe] = useState(false)
  const [name, setName] = useState(widget.information.name)
  const [externalUrl, setExternalUrl] = useState(widget.information.external_url)
  const { data: dashboard } = useCurrentDashboard()
  const { mutateAsync: updateWidget } = useUpdateWidget()
  const { data: publicWidgets } = useSelectPublicWidgets()
  const dashboardId = dashboard?.id
  const isDashboardOwner = useIsDashboardOwner()
  const isMarkdownWidget = !!widget.information.markdown
  const onUpdate = async (information: WidgetInformation) => {
    if (dashboardId) {
      const isPublicWidgetInformation = publicWidgets?.some((w) => w.information.id === widget.information.id)
      await toast.promise(
        updateWidget({
          dashboardId,
          widget: {
            ...widget,
            information: {
              ...information,
              id: isPublicWidgetInformation ? null : widget.information.id,
            },
          },
        }),
        TOAST_CUSTOM_WIDGET_UPDATE
      )
    }
  }

  const updateWidgetName = async () => {
    if (name !== widget.information.name) {
      onUpdate({
        ...widget.information,
        name,
      })
    }
  }
  const onUpdateExternalUrl = async () => {
    if (externalUrl !== widget.information.external_url) {
      onUpdate({
        ...widget.information,
        external_url: externalUrl,
      })
    }
  }

  useEffect(() => {
    if (!isPreview) {
      trackCustomWidgetView(widget)
    }
  }, [isPreview, widget])

  return (
    <>
      <Title
        title={
          <>
            <div className="md:flex items-center mb-4">
              <InlineEdit
                id={`${widget.id}`}
                value={name}
                disabled={!isDashboardOwner}
                onBlur={updateWidgetName}
                onChange={(ev) => setName(ev.target.value)}
                onKeyUp={(ev) => {
                  if (ev.code === "Enter") {
                    updateWidgetName()
                  }
                }}
                placeholder="Your widget name"
              />
              {!isMarkdownWidget && (
                <div className="md:ml-2 text-sm text-indigo-600 dark:text-indigo-400">
                  <InlineEdit
                    id={`${widget.id}`}
                    disabled={!isDashboardOwner}
                    value={externalUrl ?? ""}
                    onChange={(ev) => setExternalUrl(ev.target.value)}
                    onBlur={onUpdateExternalUrl}
                    onKeyUp={(ev) => {
                      if (ev.code === "Enter") {
                        onUpdateExternalUrl()
                      }
                    }}
                    placeholder="https://example.com"
                    inputBefore={
                      <Link to={externalUrl ?? ""} target="_blank" rel="noreferrer noopener">
                        <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />
                      </Link>
                    }
                  />
                </div>
              )}
            </div>
          </>
        }
      />
      {isMarkdownWidget ? (
        <MarkdownWidgetContent
          isModal
          isEditable={isDashboardOwner}
          widget={widget}
          onUpdate={async (markdown) => {
            onUpdate({
              ...widget.information,
              markdown,
            })
          }}
        />
      ) : (
        <div className="relative h-[1200px] max-h-[80vh] -mx-6 -mb-6">
          {isDashboardOwner || showIframe || isPreview ? (
            <ExternalUrlIframe title={name} externalUrl={externalUrl} />
          ) : (
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
              <p className="text-lg mb-4">
                This widget would like to load <strong>{externalUrl}</strong>.<br />
                Are you ok with that?
              </p>
              <Button variant="primary" size="big" onClick={() => setShowIframe(true)}>
                Yes, looks good
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  )
}
