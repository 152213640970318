import { Loading } from "components/core/modal/loading"
import { WidgetConfig, WidgetModalProps } from "components/dashboard/grid/config"
import dynamic from "next/dynamic"
import React from "react"

const CssSymbolsModal = dynamic(() => import("./cssSymbolsModal"), { loading: () => <Loading /> })

export const cssSvgSymbolsConfig: WidgetConfig = {
  slug: "css-svg-symbols",
  renderModal: (props?: WidgetModalProps) => <CssSymbolsModal {...props} />,
  icon: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x={5.75} y={5.75} width={8.5} height={8.5} rx={2.25} stroke="currentColor" strokeWidth={1.4} />
      <rect x={5.75} y={17.75} width={8.5} height={8.5} rx={2.25} stroke="currentColor" strokeWidth={1.4} />
      <rect x={17.75} y={5.75} width={8.5} height={8.5} rx={2.25} stroke="currentColor" strokeWidth={1.4} />
      <rect x={17.75} y={17.75} width={8.5} height={8.5} rx={2.25} stroke="currentColor" strokeWidth={1.4} />
    </svg>
  ),
}
