import { useRouter } from "next/router"
import { FC } from "react"
import Head from "next/head"
import { BASE_URL, isInstalled, OgImage } from "lib/utils"

type Props = {
  title: string
  description: string | undefined
  ogImage: OgImage
}

export const SEO: FC<Props> = ({ title, description, ogImage }) => {
  const router = useRouter()
  const url = `${BASE_URL}${router.asPath}`
  // When installed the title from the manifest gets automatically added
  const pageTitle = isInstalled() ? title : `${title} - Frontend Toolkit`

  const ogUrl = new URL(`${BASE_URL}/api/og`)
  ogUrl.searchParams.append("title", ogImage.title)
  ogUrl.searchParams.append("url", ogImage.url.replace(/http(s?):\/\/(www)?(\.)?/g, ""))
  if (ogImage.subTitle) {
    ogUrl.searchParams.append("subTitle", ogImage.subTitle)
  }
  if (ogImage.avatar) {
    ogUrl.searchParams.append("avatar", ogImage.avatar)
  }

  const ogImageUrl = ogUrl.toString()

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={ogImageUrl} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="fetoolkit.io" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={ogImageUrl} />
    </Head>
  )
}
