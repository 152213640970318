import { Breakpoints } from "components/dashboard/grid/config"
import { base64ConverterConfig } from "components/dashboard/widgets/base64Converter/base64ConverterConfig"
import { colorConverterConfig } from "components/dashboard/widgets/colorConverter/colorConverterConfig"
import { cssConverterConfig } from "components/dashboard/widgets/cssConverter/cssConverterConfig"
import { cssSvgSymbolsConfig } from "components/dashboard/widgets/cssSymbols/cssSymbolsConfig"
import { cssTriangleConfig } from "components/dashboard/widgets/cssTriangle/cssTriangleConfig"
import { imageOptimizerConfig } from "components/dashboard/widgets/imageOptimizer/imageOptimizerConfig"
import { jsConverterConfig } from "components/dashboard/widgets/jsConverter/jsConverterConfig"
import { jsonConverterConfig } from "components/dashboard/widgets/jsonConverter/jsonConverterConfig"
import { packageSizeConfig } from "components/dashboard/widgets/packageSize/packageSizeConfig"
import { regexTesterConfig } from "components/dashboard/widgets/regexTester/regexTesterConfig"
import { svgConverterConfig } from "components/dashboard/widgets/svgConverter/svgConverterConfig"
import { urlConverterConfig } from "components/dashboard/widgets/urlConverter/urlConverterConfig"
import { Group } from "domainModels/group"
import { Widget, WidgetInformation } from "domainModels/widget"
import { Layout, Layouts } from "react-grid-layout"

/** Widget helpers */
export const getBuiltInWidgetConfigByWidget = (widget: Widget) =>
  [
    colorConverterConfig,
    jsonConverterConfig,
    svgConverterConfig,
    cssSvgSymbolsConfig,
    cssTriangleConfig,
    imageOptimizerConfig,
    regexTesterConfig,
    packageSizeConfig,
    cssConverterConfig,
    base64ConverterConfig,
    urlConverterConfig,
    jsConverterConfig,
  ].find((bw) => bw.slug === widget.information.slug)

export const widgetMatchesSearch = ({
  keywords,
  name,
  searchQuery,
}: {
  keywords: WidgetInformation["keywords"]
  name: WidgetInformation["name"]
  searchQuery: string
}) => {
  if (!searchQuery) {
    return false
  }

  return !!keywords?.some((k) => k.toLowerCase().includes(searchQuery)) || name.toLowerCase().includes(searchQuery)
}

// TODO Use this in default ano dashboard
// export const getComputedLayoutsForWidgets = (widgets: GridWidget[]): Layouts => {
//   const layouts: Layouts = {
//     [Breakpoints.XS]: [],
//     [Breakpoints.MD]: [],
//     [Breakpoints.LG]: [],
//     [Breakpoints.XL]: [],
//     [Breakpoints.XXL]: [],
//   }

//   widgets.forEach((widget, i) => {
//     const prevXWidget = widgets?.[i - 1]

//     Object.values(Breakpoints).forEach((breakpoint) => {
//       const prevXWidgetLayout = layouts[breakpoint]?.find((l) => l.i === prevXWidget.slug)

//       let x = 0
//       let y = 0

//       if (prevXWidgetLayout) {
//         const newX = Math.min(prevXWidgetLayout.x + prevXWidgetLayout.w, GRID_COLS[breakpoint])

//         if (newX >= GRID_COLS[breakpoint]) {
//           x = 0
//           /**
//            * TODO this should be derived from previous y widget
//            * It works correctly right now but nevertheless it should be properly calculated
//            */
//           y = prevXWidgetLayout.y + 1
//         } else {
//           x = newX
//           y = prevXWidgetLayout.y
//         }
//       }

//       const widgetLayout: Layout = {
//         i: widget.slug,
//         x,
//         y,
//         minH: 5,
//         minW: 1,
//         ...widget.grid[breakpoint],
//       }

//       layouts[breakpoint].push(widgetLayout)
//     })
//   })

//   return layouts
// }

interface Rectangle {
  x1: number
  y1: number
  x2: number
  y2: number
}

export const getHoveredLayout = (layouts: Layout[], target: Layout): Layout | null => {
  // const draggingItem = window.DRAGGING_GROUP_ITEM ?? target.i

  const rectTarget: Rectangle = {
    x1: target.x,
    y1: target.y,
    x2: target.x + target.w,
    y2: target.y + target.h,
  }

  const foundHovered = layouts.find((w) => {
    if (w.i === target.i) {
      return false
    }

    const rectCompare: Rectangle = {
      x1: w.x,
      y1: w.y,
      x2: w.x + w.w,
      y2: w.y + w.h,
    }

    return overlaps(rectCompare, rectTarget) ? w : null
  })

  // const dragginItemAlreadyInSameGroup = dashboard.grouped_widgets
  //   ?.find((w) => w.id === foundHovered?.i)
  //   ?.items.some((item) => item === draggingItem)

  // The item being dragged is already part of the hovered item
  // if (dragginItemAlreadyInSameGroup) {
  //   return null
  // }

  return foundHovered ?? null
}

// Check if rectangle a overlaps rectangle b
// Each object (a and b) should have 2 properties to represent the
// top-left corner (x1, y1) and 2 for the bottom-right corner (x2, y2).
const overlaps = (a: Rectangle, b: Rectangle) => {
  // no horizontal overlap
  if (a.x1 >= b.x2 || b.x1 >= a.x2) return false

  // no vertical overlap
  if (a.y1 >= b.y2 || b.y1 >= a.y2) return false

  return true
}

export const disableGridLayoutChangeDetection = () => {
  window.GRID_LAYOUT_CHANGE_DISABLED = true
}

export const enableGridLayoutChangeDetection = () => {
  window.GRID_LAYOUT_CHANGE_DISABLED = false
}

export const gridLayoutChangeDetectionEanbled = () => !window.GRID_LAYOUT_CHANGE_DISABLED

export const getGridIds = (identifier: string) => {
  if (identifier.includes("group-")) {
    return {
      widgetId: null,
      groupId: identifier.replace("group-", ""),
    }
  } else {
    return {
      widgetId: identifier.replace("widget-", ""),
      groupId: null,
    }
  }
}

export const getGridLayouts = (widgets: Widget[], groups?: Group[] | null) => {
  const finalLayouts: Layouts = {
    [Breakpoints.XS]: [],
    [Breakpoints.MD]: [],
    [Breakpoints.LG]: [],
    [Breakpoints.XL]: [],
    [Breakpoints.XXL]: [],
  }

  widgets.forEach((w) => {
    if (w.layout) {
      Object.values(Breakpoints).forEach((breakpoint) => {
        const currentWidgetLayout = w.layout?.[breakpoint]

        if (currentWidgetLayout) {
          finalLayouts[breakpoint].push({
            ...currentWidgetLayout,
            i: `widget-${w.id?.toString()}`,
          })
        }
      })
    }
  })

  if (groups) {
    groups.forEach((g) => {
      if (g.layout) {
        Object.values(Breakpoints).forEach((breakpoint) => {
          const currentWidgetLayout = g.layout?.[breakpoint]

          if (currentWidgetLayout) {
            finalLayouts[breakpoint].push({
              ...currentWidgetLayout,
              i: `group-${g.id?.toString()}`,
            })
          }
        })
      }
    })
  }

  return finalLayouts
}
