import classNames from "classnames"
import React, { FC } from "react"
import { Layout as LayoutDomainModel } from "domainModels/layout"
import { WidgetAddMenu } from "components/dashboard/widgets/shared/widgetAddMenu"
import { WidgetActionsMenu } from "components/dashboard/widgets/shared/widgetActionsMenu"
import { getButtonClassNames } from "components/core/button"
import { Menu } from "@headlessui/react"
import { PlusIcon } from "@heroicons/react/24/outline"
import { Card } from "components/core/card"

interface Props {
  isLocked?: boolean
  isBlurred?: boolean
  isDashboardOwner?: boolean
  layout: LayoutDomainModel | null
  onRemove?: () => void
}

export const WidgetLayout: FC<Props> = ({ children, layout, isLocked, isBlurred, isDashboardOwner, onRemove }) => {
  return (
    <Card
      className={classNames("h-full flex flex-col rounded-lg relative select-none group px-4 py-3.5", {
        "blur opacity-60 dark:opacity-30 pointer-events-none": isBlurred,
      })}
    >
      {isDashboardOwner && (
        <>
          <WidgetAddAction layout={layout} position="top" />
          <WidgetAddAction layout={layout} position="bottom" />
          {!isLocked && (
            <div className="gridItemDraggable absolute inset-0 text-gray-400 cursor-grab active:cursor-grabbing hover:text-gray-500 dark:hover:text-gray-100" />
          )}
          <div className="absolute z-20 top-3 right-1">
            <WidgetActionsMenu isLocked={isLocked} layout={layout} onRemove={onRemove} />
          </div>
        </>
      )}

      {children}
    </Card>
  )
}

const WidgetAddAction: FC<{ layout: LayoutDomainModel | null; position: "top" | "bottom" }> = ({
  layout,
  position,
}) => (
  <div
    className={classNames(
      "opacity-0 h-[21px] group-hover:opacity-100 transition-opacity duration-500 absolute z-20 flex justify-center items-center addWidgetAction",
      {
        "-top-[19px] inset-x-0": position === "bottom",
        "-bottom-[22px] inset-x-0": position === "top",
      }
    )}
  >
    <div className="flex-1 -mt-1 h-px bg-gradient-to-r from-transparent to-gray-300/60 dark:to-gray-800" />
    <WidgetAddMenu
      layout={layout}
      position={position}
      trigger={
        <Menu.Button
          title="Add new widget"
          className={classNames(getButtonClassNames("naked", "small"), "py-0 h-full")}
        >
          <PlusIcon className="w-4 h-4" />
        </Menu.Button>
      }
    />
    <div className="flex-1 -mt-1 h-px bg-gradient-to-l from-transparent to-gray-300/60 dark:to-gray-800" />
  </div>
)
