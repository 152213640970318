import classNames from "classnames"
import { InputLabel } from "components/core/inputLabel"
import { Spinner } from "components/core/spinner"
import { useNpm } from "components/dashboard/widgets/packageSize/packageSizeData"
import { PackageSuggestion } from "components/dashboard/widgets/packageSize/types"
import React, { FC, useCallback, useEffect, useState } from "react"

interface Props {
  id: string
  searchQuery: string
  setSearchQuery: (searchQuery: string) => void
  onChange: (suggestion: PackageSuggestion) => void
}

export const PackageSizeSearch: FC<Props> = ({ id, searchQuery, setSearchQuery, onChange }) => {
  const [showSuggestions, setshowSuggestions] = useState(false)
  const { data: packageSuggestions, isLoading } = useNpm(searchQuery)
  const hasItems = packageSuggestions && packageSuggestions.length > 0

  const onFocus = (ev: any) => {
    const gridItem = ev.target.closest(".react-grid-item")

    if (gridItem) {
      gridItem.style.zIndex = 10
    }

    if (packageSuggestions) {
      setshowSuggestions(true)
    }
  }

  const onClick = useCallback(
    (ev: any) => {
      if (showSuggestions && !ev.target?.closest("#packageSizeSuggestions") && ev.target.id !== id) {
        setshowSuggestions(false)
      }
    },
    [id, showSuggestions]
  )

  const onKeyUp = (ev: any) => {
    if (ev.key === "Escape") {
      setshowSuggestions(false)
    } else if (!showSuggestions) {
      setshowSuggestions(true)
    }
  }

  useEffect(() => {
    document.body.addEventListener("click", onClick)

    return () => {
      document.body.removeEventListener("click", onClick)
    }
  }, [onClick, showSuggestions])

  return (
    <div id="packageSizeSearch" className="relative w-full">
      <div className="mt-1">
        <InputLabel
          type="text"
          id={id}
          value={searchQuery}
          autoComplete="off"
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          placeholder="Enter package name"
          onChange={(ev) => setSearchQuery(ev.target.value)}
          inputClassName={classNames("focus-visible:z-30", {
            "!rounded-b-none": showSuggestions && hasItems,
          })}
          inputIconAfter={isLoading && <Spinner className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />}
          inputIconBefore={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 relative z-10 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          }
          className={classNames({
            "rounded-b-none": showSuggestions && hasItems,
          })}
        />

        {showSuggestions && hasItems && (
          <div
            id="packageSizeSuggestions"
            className="absolute z-20 divide-y dark:divide-gray-800/40 top-full left-0 w-full max-h-80 overflow-auto shadow-sm text-sm bg-white border border-gray-300 border-t-0 rounded-b-md resize-none dark:text-gray-400 dark:hover:text-gray-100 dark:bg-gray-900 dark:border-none dark:shadow"
          >
            {packageSuggestions?.map((suggestion) => (
              <button
                className="relative p-3 w-full block text-left hover:bg-gray-100 dark:hover:bg-gray-800/40 focus:z-10 focus:bg-gray-100 dark:focus:bg-gray-800/40"
                key={suggestion.package.name}
                onClick={() => {
                  onChange(suggestion)
                  setSearchQuery(suggestion.package.name)
                  setshowSuggestions(false)
                }}
              >
                <span className="block w-full text-gray-800 dark:text-gray-200">{suggestion.package.name}</span>
                <span className="text-gray-500">{suggestion.package.description}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
