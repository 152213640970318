import classNames from "classnames"
import { useColorMode } from "components/core/colorModeContent"
import Prism from "prismjs"
import { FC, useEffect } from "react"

interface Props {
  code: string
  language: string
  wrap?: boolean
  className?: string
}

export const CodeOutput: FC<Props> = ({ code, language, className, wrap = false }) => {
  const { darkModeEnabled } = useColorMode()

  useEffect(() => {
    Prism.highlightAll()
  }, [code])

  return (
    <pre className={classNames("h-full rounded-md", className)}>
      {darkModeEnabled ? <CssDark /> : <CssLight />}
      <code
        className={classNames(language, {
          "!whitespace-normal !break-words": wrap,
        })}
      >
        {code}
      </code>
    </pre>
  )
}

const CssLight = () => (
  <>
    <style jsx global>{`
      code[class*="language-"],
      pre[class*="language-"] {
        color: black;
        background: none;
        text-shadow: 0 1px white;
        font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
        font-size: 12px;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        line-height: 1.5;

        -moz-tab-size: 4;
        -o-tab-size: 4;
        tab-size: 4;

        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
      }

      pre[class*="language-"]::-moz-selection,
      pre[class*="language-"] ::-moz-selection,
      code[class*="language-"]::-moz-selection,
      code[class*="language-"] ::-moz-selection {
        text-shadow: none;
        background: #4f46e5;
      }

      pre[class*="language-"]::selection,
      pre[class*="language-"] ::selection,
      code[class*="language-"]::selection,
      code[class*="language-"] ::selection {
        text-shadow: none;
        background: #4f46e5;
      }

      @media print {
        code[class*="language-"],
        pre[class*="language-"] {
          text-shadow: none;
        }
      }

      /* Code blocks */
      pre[class*="language-"] {
        padding: 8px 12px;
        overflow: auto;
      }

      :not(pre) > code[class*="language-"],
      pre[class*="language-"] {
        background: rgba(0, 0, 0, 0.02);
      }

      /* Inline code */
      :not(pre) > code[class*="language-"] {
        padding: 0.1em;
        border-radius: 0.3em;
        white-space: normal;
      }

      .token.comment,
      .token.prolog,
      .token.doctype,
      .token.cdata {
        color: slategray;
      }

      .token.punctuation {
        color: #999;
      }

      .token.namespace {
        opacity: 0.7;
      }

      .token.property,
      .token.tag,
      .token.boolean,
      .token.number,
      .token.constant,
      .token.symbol,
      .token.deleted {
        color: #905;
      }

      .token.selector,
      .token.attr-name,
      .token.string,
      .token.char,
      .token.builtin,
      .token.inserted {
        color: #690;
      }

      .token.operator,
      .token.entity,
      .token.url,
      .language-css .token.string,
      .style .token.string {
        color: #9a6e3a;
      }

      .token.atrule,
      .token.attr-value,
      .token.keyword {
        color: #07a;
      }

      .token.function,
      .token.class-name {
        color: #dd4a68;
      }

      .token.regex,
      .token.important,
      .token.variable {
        color: #e90;
      }

      .token.important,
      .token.bold {
        font-weight: bold;
      }
      .token.italic {
        font-style: italic;
      }

      .token.entity {
        cursor: help;
      }
    `}</style>
  </>
)

const CssDark = () => (
  <>
    <style jsx global>{`
      code[class*="language-"],
      pre[class*="language-"] {
        color: #d6deeb;
        font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        line-height: 1.5;
        font-size: 12px;

        -moz-tab-size: 4;
        -o-tab-size: 4;
        tab-size: 4;

        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
      }

      pre[class*="language-"]::-moz-selection,
      pre[class*="language-"] ::-moz-selection,
      code[class*="language-"]::-moz-selection,
      code[class*="language-"] ::-moz-selection {
        text-shadow: none;
        background: #;
      }

      pre[class*="language-"]::selection,
      pre[class*="language-"] ::selection,
      code[class*="language-"]::selection,
      code[class*="language-"] ::selection {
        text-shadow: none;
        background: #;
      }

      @media print {
        code[class*="language-"],
        pre[class*="language-"] {
          text-shadow: none;
        }
      }

      /* Code blocks */
      pre[class*="language-"] {
        padding: 1em;
        overflow: auto;
      }

      :not(pre) > code[class*="language-"],
      pre[class*="language-"] {
        color: white;
        background: rgba(0, 0, 0, 0.1);
      }

      :not(pre) > code[class*="language-"] {
        padding: 0.1em;
        white-space: normal;
      }

      .token.comment,
      .token.prolog,
      .token.cdata {
        color: rgb(99, 119, 119);
        font-style: italic;
      }

      .token.punctuation {
        color: rgb(199, 146, 234);
      }

      .namespace {
        color: rgb(178, 204, 214);
      }

      .token.deleted {
        color: rgba(239, 83, 80, 0.56);
        font-style: italic;
      }

      .token.symbol,
      .token.property {
        color: rgb(128, 203, 196);
      }

      .token.tag,
      .token.operator,
      .token.keyword {
        color: rgb(127, 219, 202);
      }

      .token.boolean {
        color: rgb(255, 88, 116);
      }

      .token.number {
        color: rgb(247, 140, 108);
      }

      .token.constant,
      .token.function,
      .token.builtin,
      .token.char {
        color: rgb(130, 170, 255);
      }

      .token.selector,
      .token.doctype {
        color: rgb(199, 146, 234);
        font-style: italic;
      }

      .token.attr-name,
      .token.inserted {
        color: rgb(173, 219, 103);
        font-style: italic;
      }

      .token.string,
      .token.url,
      .token.entity,
      .language-css .token.string,
      .style .token.string {
        color: rgb(173, 219, 103);
      }

      .token.class-name,
      .token.atrule,
      .token.attr-value {
        color: rgb(255, 203, 139);
      }

      .token.regex,
      .token.important,
      .token.variable {
        color: rgb(214, 222, 235);
      }

      .token.important,
      .token.bold {
        font-weight: bold;
      }

      .token.italic {
        font-style: italic;
      }
    `}</style>
  </>
)
