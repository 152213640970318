import { Modal } from "components/core/modal/modal"
import { CustomWidgetModal } from "components/dashboard/widgets/customWidgets/customWidgetModal"
import React, { FC, useEffect, useState } from "react"
import { Widget, WidgetInformation } from "domainModels/widget"
import { MarkdownWidgetContent } from "components/dashboard/widgets/customWidgets/markdownWidgetContent"
import { useCurrentDashboard, useIsDashboardOwner } from "reactQuery/dashboard"
import toast from "react-hot-toast"
import { useDeleteWidget, useUpdateWidget } from "reactQuery/widget"
import { TOAST_CUSTOM_WIDGET_UPDATE } from "components/dashboard/toastConfig"
import { InlineEdit } from "components/core/inlineEdit"
import { WidgetLayout } from "components/dashboard/widgets/shared/widgetLayout"
import { useUiContext } from "components/providers/uiProvider"
import { widgetMatchesSearch } from "components/dashboard/grid/helpers"

interface Props {
  widget: Widget
}

export const MarkdownWidget: FC<Props> = ({ widget }) => {
  const { data: dashboard } = useCurrentDashboard()
  const { mutateAsync: updateWidget } = useUpdateWidget()
  const [name, setName] = useState(widget.information.name)
  const [showModal, setShowModal] = useState(false)
  const dashboardId = dashboard?.id
  const { mutateAsync: deleteWidget } = useDeleteWidget()
  const { value } = useUiContext()
  const { searchQuery } = value
  const isDashboardOwner = useIsDashboardOwner()
  const isBlurred =
    searchQuery.length > 0 &&
    !widgetMatchesSearch({ name: widget.information.name, keywords: widget.information.keywords, searchQuery }) &&
    !widget.information.markdown?.toLowerCase().includes(searchQuery)

  const onRemove = async () => {
    if (widget && dashboard?.id) {
      await deleteWidget({ widget, dashboardId: dashboard.id })
    }
  }

  const updateWidgetName = async () => {
    if (name !== widget.information.name) {
      onUpdate({
        ...widget.information,
        name,
      })
    }
  }

  const onUpdate = async (information: WidgetInformation) => {
    if (dashboardId) {
      setName(information.name)

      await toast.promise(
        updateWidget({
          dashboardId,
          widget: {
            ...widget,
            information: {
              ...information,
              id: widget.information.id,
            },
          },
        }),
        TOAST_CUSTOM_WIDGET_UPDATE
      )
    }
  }

  useEffect(() => {
    if (widget.information.name) {
      setName(widget.information.name)
    }
  }, [widget.information.name])

  return (
    <>
      <WidgetLayout
        layout={widget.layout}
        isLocked={widget.layout?.xxl.static}
        isBlurred={isBlurred}
        isDashboardOwner={isDashboardOwner}
        onRemove={onRemove}
      >
        <div className="flex items-center mb-3">
          <div className="relative z-10 font-medium truncate max-w-full mr-4 -mt-0.5">
            <InlineEdit
              id={`${widget.id}`}
              disabled={!isDashboardOwner}
              value={name}
              onChange={(ev) => setName(ev.target.value)}
              onBlur={updateWidgetName}
              maxLength={40}
              onKeyUp={(ev) => {
                if (ev.code === "Enter") {
                  updateWidgetName()
                }
              }}
            />
          </div>
        </div>

        <div
          className="overflow-auto h-full cursor-pointer"
          onClick={() => {
            if (window.getSelection()?.toString() === "") {
              setShowModal(true)
            }
          }}
        >
          <MarkdownWidgetContent
            isEditable={false}
            widget={widget}
            onUpdate={(markdown) => onUpdate({ ...widget.information, markdown })}
          />
        </div>

        <Modal show={showModal} className={"max-w-6xl"} onClose={() => setShowModal(false)}>
          <CustomWidgetModal widget={widget} />
        </Modal>
      </WidgetLayout>
    </>
  )
}
