import React, { FC, useRef, useState } from "react"
import { Widget } from "domainModels/widget"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { Link } from "components/core/link"
import { CodeOutput } from "components/core/codeOutput"
import classNames from "classnames"
import { Button } from "components/core/button"
import { TextareaLabel } from "components/core/textareaLabel"
import toast from "react-hot-toast"
import { CodeProps } from "react-markdown/lib/ast-to-react"

interface Props {
  widget: Widget
  onUpdate: (markdown: string) => Promise<void>
  isModal?: boolean
  isEditable?: boolean
}

const MAX_CHARS = 4096

export const MarkdownWidgetContent: FC<Props> = ({ widget, onUpdate, isModal, isEditable = true }) => {
  const markdownRef = useRef<HTMLDivElement | null>(null)
  const [markdown, setMarkdown] = useState(widget.information.markdown)
  const [height, setHeight] = useState<number>()
  const [isEditMode, setIsEditMode] = useState(false)
  const maxLengthExceeded = !markdown || MAX_CHARS - markdown.length < 0
  const charactersLeft = markdown ? MAX_CHARS - markdown.length : MAX_CHARS

  const onCancel = () => {
    setMarkdown(widget.information.markdown)
    setHeight(undefined)
    setIsEditMode(false)
  }
  const onSave = async () => {
    if (markdown?.length && !maxLengthExceeded) {
      await onUpdate(markdown)
    } else {
      setMarkdown(widget.information.markdown)
      toast.error(`Couldn't update widget. You have probably exceeded the character limit.`)
    }

    setHeight(undefined)
    setIsEditMode(false)
  }

  return (
    <div
      className={classNames("max-w-2xl mx-auto", {
        "h-full": isEditMode,
      })}
    >
      {isEditMode ? (
        <div className="flex flex-col h-full p-5 -mx-5">
          <TextareaLabel
            id="noteWidgetMarkdown"
            value={markdown ?? ""}
            onChange={(ev) => setMarkdown(ev.target.value)}
            resize={isModal}
            style={{ minHeight: isModal ? height : "100%" }}
          />
          <div className="flex justify-between items-center my-3">
            <div className="text-sm text-gray-700 dark:text-gray-300">
              {markdown && charactersLeft <= 100 && <>{charactersLeft} characters left</>}
            </div>
            <div>
              <Button onClick={onCancel} className="relative z-10 mr-2" size="small">
                Cancel
              </Button>
              <Button
                className="relative z-10"
                variant="primary"
                onClick={onSave}
                size="small"
                disabled={maxLengthExceeded || !markdown}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          ref={markdownRef}
          className={classNames(
            "relative z-10 text-sm rounded-md font-normal select-text break-words text-gray-700 dark:text-gray-300",
            {
              "md:my-8 p-5 -mx-5": isModal,
              "hover:bg-gray-100 dark:hover:bg-black dark:hover:bg-opacity-10": isEditable,
            }
          )}
          onClick={(ev) => {
            if (isEditable && isModal && window.getSelection()?.toString() === "") {
              setHeight(markdownRef.current?.clientHeight)

              if ((ev.target as HTMLElement).tagName !== "A") {
                setIsEditMode(true)
              }
            }
          }}
        >
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              h1: ({ node, ...props }) => <h1 className="text-xl mb-4 font-medium" {...props} />,
              h2: ({ node, ...props }) => <h2 className="text-lg mb-4 font-medium" {...props} />,
              h3: ({ node, ...props }) => <h3 className="text-md mb-4 font-medium" {...props} />,
              p: ({ node, ...props }) => <p className="mb-4 text-sm" {...props} />,
              hr: ({ node, ...props }) => <hr className="my-3 border-gray-200 dark:border-gray-700" {...props} />,
              ul: ({ children, ...rest }) => {
                const hasTaskList = rest.className?.includes("contains-task-list")

                return (
                  <ul
                    className={classNames("space-y-1", {
                      "mb-4": hasTaskList,
                      "list-disc list-outside ml-4 mb-4": !hasTaskList,
                    })}
                  >
                    {children}
                  </ul>
                )
              },
              a: ({ node, ...props }) => (
                <Link
                  to={props.href as string}
                  target="_blank"
                  {...props}
                  onClick={(ev) => ev.stopPropagation()}
                  className="break-all"
                />
              ),
              table: ({ node, ...props }) => (
                <div className=" overflow-x-auto">
                  <table
                    className="min-w-full divide-y mb-4 divide-gray-200 dark:divide-gray-700 dark:divide-opacity-30"
                    {...props}
                  />
                </div>
              ),
              thead: ({ node, ...props }) => (
                <thead className="bg-gray-50 dark:bg-gray-900 dark:bg-opacity-30" {...props} />
              ),
              th: ({ node, isHeader, ...props }) => (
                <th
                  className="px-3 py-2 text-left text-xs uppercase tracking-wider text-gray-500 dark:text-gray-400"
                  {...(props as any)}
                />
              ),
              td: ({ node, isHeader, ...props }) => (
                <td className="px-3 py-2 whitespace-nowrap text-gray-900 dark:text-gray-400" {...(props as any)} />
              ),
              tbody: ({ node, ...props }) => (
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700 dark:divide-opacity-30" {...props} />
              ),
              input: ({ node, ...props }) =>
                props.type === "checkbox" ? (
                  <input
                    type="checkbox"
                    disabled={props.disabled}
                    checked={props.checked}
                    className={classNames("w-4 h4 rounded mr-1", {
                      "text-indigo-600": props.checked,
                      "border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800": !props.checked,
                    })}
                  />
                ) : (
                  <input {...props} />
                ),
              code: Code,
              blockquote: ({ node, ...props }) => (
                <blockquote className="border-l-4 pl-4 italic dark:border-gray-700" {...props} />
              ),
            }}
          >
            {widget.information.markdown ?? ""}
          </ReactMarkdown>
        </div>
      )}
    </div>
  )
}

const Code: FC<CodeProps> = ({ inline, className, children }) => {
  const match = /language-(\w+)/.exec(className || "")

  return (
    <span
      className={classNames({
        "inline-flex max-w-full": inline,
        "block mb-4": !inline,
      })}
    >
      <CodeOutput
        language={match?.[1] ? `language-${match?.[1]}` : "language-js"}
        code={String(children).replace(/\n$/, "")}
        className={classNames({ "!py-0.5 !px-1.5": inline })}
      />
    </span>
  )
}
