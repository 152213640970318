import React, { FC, useState } from "react"
import { FileUpload, ImageFileUpload } from "components/core/imageFileUpload"
import { Modal } from "components/core/modal/modal"
import { Widget } from "domainModels/widget"
import { imageOptimizerConfig } from "components/dashboard/widgets/imageOptimizer/imageOptimizerConfig"

interface Props {
  widget: Widget
}

export const ImageOptimizerPreview: FC<Props> = ({ widget }) => {
  const [input, setInput] = useState<FileUpload>()

  return (
    <div className="relative h-full">
      <ImageFileUpload onChange={setInput} />
      <Modal show={!!input} className="md:max-w-[90vw]" onClose={() => setInput(undefined)}>
        {imageOptimizerConfig.renderModal({ input, widget })}
      </Modal>
    </div>
  )
}
